import { Card, CardBody, CardTitle, CardSubtitle, Table, Col, FormGroup, Input, UncontrolledAccordion, AccordionItem, AccordionHeader, AccordionBody, Accordion, Button } from "reactstrap";
import json from "../../services/jsonData.json";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SlogDetail from "../../views/SlogDetail";
import parse from 'html-react-parser';
import Highlighter from "react-highlight-words";
import Loader from "../../layouts/loader/Loader";

const ProjectTables = (props) => {
  let navigate = useNavigate();
  const [open, setOpen] = useState('');
  const [searchString, setsearchString] = useState('');
  const [isOpenAll, setIsOpenAll] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [favorite, setFavorite] = useState(0);



  const toggle = (id) => {
    if (open === id) {
      setOpen();
      // setIsOpenAll('')
    } else {
      setOpen(id);
      // setIsOpenAll('')
    }
  };

  const search = (txt) => {
    setIsSearching(true)
    setTimeout(() => {
      if (txt) {
        setsearchString(txt)
        console.log(txt)
      } else
        setsearchString('')
      setIsSearching(false)
    }, 500)
  }

  function toLowerCaseJSON(obj) {
    if (typeof obj === 'object' && obj !== null) {
      if (Array.isArray(obj)) {
        return obj.map(item => toLowerCaseJSON(item));
      } else {
        return Object.keys(obj).reduce((acc, key) => {
          const lowerKey = key.toLowerCase();
          acc[lowerKey] = toLowerCaseJSON(obj[key]);
          return acc;
        }, {});
      }
    } else if (typeof obj === 'string') {
      return obj.toLowerCase();
    } else {
      return obj;
    }
  }

  const searchCondition = (index, tdata) => {
    // console.log(tdata, 'tdata')
    // console.log(JSON.stringify(tdata), 'sttdata')
    if (searchString) {
      let lwrCase = toLowerCaseJSON(tdata);
      let stringData = JSON.stringify(lwrCase);
      return stringData.includes(searchString.toLowerCase())
    }
    return true
  }

  const fav = ((id) => {
    let favorited = localStorage.getItem('fav');
    if (favorited) {
      let parsefavorited = JSON.parse(favorited)
      parsefavorited.push(id)
      localStorage.setItem('fav', JSON.stringify(parsefavorited))
    }else{
      let parsefavorited = []
      parsefavorited.push(id)
      localStorage.setItem('fav', JSON.stringify(parsefavorited))
    }
    setFavorite(1);
  })
  const infav = ((id) => {
    let favorited = localStorage.getItem('fav');
    if (favorited) {
      let parsefavorited = JSON.parse(favorited)
      parsefavorited = parsefavorited.filter((item)=>item !== id)
      localStorage.setItem('fav', JSON.stringify(parsefavorited))
    }
    setFavorite(0);
  })

  useEffect(() => {
    let favorited = localStorage.getItem('fav');
    if (favorited){
      let parsefavorited = JSON.parse(favorited)
        setFavorite( parsefavorited.includes(props.id) ? 1 : 0)
    }

    // setFavorite( favorited === '1' ? 1 : 0)
  });

  return (
    <div>
      <Card style={{ borderTopLeftRadius: '25px', borderBottomRightRadius: '25px' }}>
        <CardBody>
          <div style={{ display: 'flex', justifyContent: "space-between" }}>
            <i onClick={() => navigate("/gallery")} style={{ fontSize: '30px' }} class="bi bi-arrow-left-short"></i>
            <p>
              {favorite ?
                <i onClick={() => infav(props.id)} class="bi bi-heart-fill" style={{ cursor: 'pointer', fontSize: '22px', textAlign: 'right', marginRight: '10px' }} ></i>
                :
                <i onClick={() => fav(props.id)} class="bi bi-heart" style={{ cursor: 'pointer', fontSize: '22px', textAlign: 'right', marginRight: '10px' }} ></i>

              }
              <i onClick={() => navigate("/starter")} style={{ cursor: 'pointer', fontSize: '25px', textAlign: 'right' }} class="bi bi-house"></i>
            </p>
          </div>

          <CardTitle tag="h5" style={{ textAlign: 'center' }}>{json.chapters[props.id ?? 1].chapter_heading}</CardTitle>
          <CardSubtitle style={{ textAlign: 'center' }} className="mb-2 text-muted" tag="h6">
            Total headings  - {json.chapters[props.id ?? 1].slogs.length}
          </CardSubtitle>
          <Col sm="12" lg="12" style={{ textAlign: 'center' }}>
            <FormGroup style={{ padding: '10px 30px', alignItems: 'center' }}>
              {/* <Label for="searchfield">
              Date
            </Label> */}

              <Input
                id="searchfield"
                name="searchfield"
                placeholder="Search"
                style={{ borderRadius: '20px', textAlign: 'center' }}
                type="text"
                onChange={(e) => search(e.target.value)}
              />

            </FormGroup>

          </Col>
          {!isOpenAll ?
            <div
              onClick={() => { setIsOpenAll(1); setOpen(1); }}
              style={{ cursor: 'pointer', fontSize: '13px', fontWeight: 'bold', width: '100%', textAlign: 'right', marginBottom: '10px' }}>
              Open All
              {/* <i  onClick={() => { setIsOpenAll(1); setOpen(1); }} style={{ cursor: 'pointer', fontSize: '20px', textAlign: 'right',fontWeight: 'bold' }} class="bi bi-arrows-angle-expand"></i> */}
            </div>
            :
            <div
              onClick={() => { setIsOpenAll(''); setOpen(''); }}
              style={{ cursor: 'pointer', fontSize: '13px', fontWeight: 'bold', width: '100%', textAlign: 'right', marginBottom: '10px' }}>
              Close All
              {/* <i onClick={() => { setIsOpenAll(''); setOpen(''); }} style={{ cursor: 'pointer', fontSize: '20px', textAlign: 'right', fontWeight: 'bold' }} class="bi bi-arrows-angle-contract"></i> */}
            </div>
          }
          {isSearching ? <Loader></Loader> :
            <Accordion flush open={open} toggle={toggle}>
              {json.chapters[props.id ?? 1].slogs.map((tdata, index) => (
                searchCondition(index, tdata) &&
                <AccordionItem>
                  <AccordionHeader targetId={isOpenAll ? isOpenAll : index + 1}>
                    <div>

                      <strong style={{ minWidth: '100%', display: 'flex' }}>
                        <code style={{ fontSize: '18px' }}>
                          {index + 1 + '. '}
                        </code>
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[searchString]}
                          autoEscape={true}
                          textToHighlight={String(parse(tdata.heading))}
                        />
                        {/* {parse(tdata.heading)} */}
                      </strong>
                      {/* </code> {parse(tdata.heading.split('(')[0])}</strong> */}
                      {/* <div style={{ minWidth: '100%' }}> {tdata.heading.split('(')[1] &&
                      <p className="text-muted" style={{ fontSize: '14px', marginTop: '2px', marginBottom: '2px' }}>( {parse(tdata.heading.split('(')[1])}</p>
                    }
                    </div> */}

                    </div>



                  </AccordionHeader>
                  <AccordionBody accordionId={isOpenAll ? isOpenAll : index + 1}>

                    <SlogDetail slogId={props.id} id={index} searchString={searchString}></SlogDetail>
                    {/* You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the{' '}
                  <code>
                    .accordion-body
                  </code>
                  , though the transition does limit overflow. */}
                  </AccordionBody>
                </AccordionItem>
              ))}
            </Accordion>
          }

          {/* <Table className="no-wrap mt-3 align-middle" responsive borderless>

            <tbody>
              {json.chapters[props.id ?? 1].slogs.map((tdata, index) => (
                <tr style={{ cursor: 'pointer' }} key={index} className="border-top" onClick={() => navigate("/slog/" + props.id + "/detail/" + index)}>
                  <td>
                    <div className="d-flex align-items-center p-2">
                      <i className="bi bi-wallet"></i>
                      <div className="ms-3">
                        <h6 className="mb-0">{tdata.heading.split('(')[0]}</h6>
                        {tdata.heading.split('(')[1] &&
                          <span className="text-muted">( {tdata.heading.split('(')[1]}</span>
                        }
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> */}
        </CardBody>
      </Card>
    </div>
  );
};

export default ProjectTables;
